import Image, { StaticImageData } from 'next/image'
import React from 'react'

interface CustomImageProps {
    src: string | StaticImageData
    alt: string
    className?: string
    width: number
    height: number
    style?: any
    id?: string
    notResponsive?: boolean
}

const CustomImage = ({
    src,
    alt,
    className,
    width,
    height, style, id, notResponsive }: CustomImageProps) => {
    return (
        <Image
            src={src}
            alt={alt}
            className={className}
            width={width}
            height={height}
            layout={notResponsive ? "" : "responsive"}
            priority={false}
            loading="lazy"
            style={{ ...style }}
            id={id}
        />
    )
}

export default CustomImage