import React from 'react';
import parse from 'html-react-parser';

export default function SectionHeading({
  title = '',
  titleUp = '',
  titleDown = '',
  subTitle = '',
  center,
}) {
  return (
    <div
      className={`cs-section-heading cs-style-1 ${center ? 'text-center' : ''}`}
      style={{ marginTop: '2rem' }}
    >
      {titleUp && (
        <h2
          className="cs-section-subtitle text-uppercase cs-semibold m-0 cs-fs-32"
          style={{ marginBottom: '5px' }} // Replacing Spacing
        >
          {parse(titleUp)}
        </h2>
      )}

      {title && (
        <h3
          className="cs-section-title cs-fs-72"
          style={{ marginTop: '0rem', marginBottom: titleDown ? '5px' : '0' }} // Conditional margin for titleDown
        >
          {parse(title)}
        </h3>
      )}

      {titleDown && (
        <h3
          className="cs-section-subtitle text-uppercase cs-semibold m-0 cs-fs-32"
          style={{ marginBottom: subTitle ? '6.25rem' : '0' }} // Adjusting margin for subTitle presence
        >
          {parse(titleDown)}
        </h3>
      )}

      {subTitle && (
        <p
          className="m-0"
          style={{ marginTop: '6.25rem' }} // Replacing Spacing
        >
          {parse(subTitle)}
        </p>
      )}
    </div>
  );
}
