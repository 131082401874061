"use client";

import React from "react";
import SectionHeading from "../../ui/SectionHeading";
import Button from "../../ui/Button";
import Image from "next/image";
import logo from "../../../../public/images/Website_Logo.webp";
import { useTranslations } from "next-intl";

export const metadata = {
  title: "404 - Page not Found..!",
};

const PageNotFound = () => {
  const t = useTranslations("notFound");

  return (
    <div className="cs-error cs-center text-center cs-gray-bg-1">
      <div className="container">
        <Image
          src={logo}
          alt="Logo"
          width={250}
          loading="lazy"
          priority={false}
        />
        <SectionHeading
          titleUp={t("err")}
          title={t("title")}
          variantColor="cs_white_color"
        />
        <Button
          btnText={t("btnText")}
          btnUrl="/"
        />
      </div>
    </div>
  );
};

export default PageNotFound;
